import { Link } from "gatsby";
import React from "react";

const FeaturdPostsSidebarComponent = () => {
    let featuredPosts = [
        {
            "title": "Google Maps Distance Matrix API - Outputting More Than 25 Destinations",
            "summary": "The Google Maps Distance Matrix API has a limit of 25 destinations per request. This post demonstrates how to process more than imposed limit by batching API calls.",
            "url": "/Blog/2024/09/13/Google-Maps-Distance-Matrix-25-Requests-Limit",
            "image": "https://ik.imagekit.io/surinderbhomra/Blog/Stock/PaulMarlowPinsOnMap.jpg??tr=w-700"
        },
        {
            "title": "Side Hustling With UserTesting.com",
            "summary": "I've been using UserTesting.com since June for a side hustle to supplement my monthly investment contribution. I discuss whether it has been a worthy way to accumalate additional income.",
            "url": "/Blog/2024/08/24/Side-Hustling-With-UserTesting",
            "image": "https://ik.imagekit.io/surinderbhomra/Blog/Stock/SideHustleIncreaseIncome-FreePik-PchVector.png?tr=w-700"
        },
        {
            "title": "Websites and The Environment",
            "summary": "When building any application, the last thing on any developer's mind is how a build will impact the environment. We'll be discussing tools that can measure the size of the carbon footprint your website leaves behind.",
            "url": "/Blog/2022/08/05/Websites-and-The-Environment",
            "image": "https://ik.imagekit.io/surinderbhomra/Blog/Stock/VeeterzyTreeInForestOfPlants.jpg?tr=w-700"
        },
    ]

    return (
        <div className="py-4">
            <h2 className="text-sm tracking-wide text-gray-500 dark:text-gray-400 mb-1 font-medium">
                Featured Posts
            </h2>
            {featuredPosts.map((node) => (
                <div key={node.url} className="pb-6 grid grid-cols">
                    <Link to={node.url} className="overflow-hidden shadow-lg">
                        <img className="w-full" src={node.image} alt={node.title} loading="lazy" />
                        <div className="px-6 py-4">
                            <div className="font-bold text-xl mb-2">{node.title}</div>
                            <p className="text-gray-700 text-base">
                                {node.summary}
                            </p>
                        </div>
                    </Link>
                </div>
            ))}
        </div>
    )
}

export default FeaturdPostsSidebarComponent