import React from "react";
import PaginationComponent from "../global/pagination";
import TagLinkComponent from "./tag-link";
import LazyloadImage from "../global/lazyloadimage";
import { Link } from "gatsby";
import FeaturdPostsSidebarComponent from "./featured-posts-sidebar";
import TwemojiComponent from "../core/twemoji";
const moment = require(`moment`);

const BlogIndexListingComponent = ({ data, path, pageContext, isFiltered = false }) => {
    return (
        <>
            <div className="h-feed hfeed xl:grid xl:gap-x-6 grid-cols-70/30">
                <div className="xl:mr-3">
                    <ul>
                        {data.edges.map(({ node, index }) => {
                            return (
                                <li key={node.fields.slug} className="py-12 border-b">
                                    <article className="h-entry hentry space-y-2 xl:items-baseline">
                                        <div className="space-y-3 xl:col-span-3">
                                            <div>
                                                <div>
                                                    <dt className="sr-only">Published on</dt>
                                                    <dd className="flex text-base font-medium leading-6 text-gray-500 dark:text-gray-400">
                                                        <time dateTime={moment(node.frontmatter.date).format("YYYY-MM-DDThh:mm:ssTZD")} className="dt-published published flex items-center">
                                                            <TwemojiComponent emoji="calendar" size="" />
                                                            <span className="ml-1.5">
                                                                {moment(node.frontmatter.date).format("MMM DD, YYYY")}
                                                            </span>
                                                        </time>
                                                        <span className="mx-2">-</span>
                                                        <div className="flex items-center">
                                                            <TwemojiComponent emoji="hourglass-not-done" size="" />
                                                            <span className="ml-1.5">{node.timeToRead} min read</span>
                                                        </div>
                                                    </dd>
                                                </div>
                                                <h2 className="text-3xl font-bold leading-9 tracking-tight">
                                                    <Link to={node.fields.slug} className="p-name entry-title text-gray-900 dark:text-gray-100">
                                                        {node.frontmatter.title}
                                                    </Link>
                                                </h2>
                                                {node.frontmatter.tags && (
                                                    <div className="flex flex-wrap">
                                                        {node.frontmatter.tags.map((tag) => (
                                                            <TagLinkComponent key={tag} text={tag} />
                                                        ))}
                                                    </div>
                                                )}
                                                {node.frontmatter.teaserImage !== "" ? 
                                                    (<div className="featured-image pt-2">
                                                        <LazyloadImage 
                                                            src={node.frontmatter.teaserImage.replace(" ", "%20")}  
                                                            srcsetSizes={[{ imageWidth: 1000, viewPortWidth: 992 }, { imageWidth: 768, viewPortWidth: 768 }, { imageWidth: 500, viewPortWidth: 500 }]}
                                                            alt={node.frontmatter.title} />
                                                    </div>) : null}
                                            </div>
                                            <div className="p-summary entry-summary prose text-gray-500 max-w-none dark:text-gray-400" dangerouslySetInnerHTML={{ __html: node.html }} />
                                        </div>
                                    </article>
                                </li>
                            )
                        })}
                    </ul>
                    <PaginationComponent path={path} currentPageNo={pageContext.currentPage} totalPages={pageContext.totalPages} hasPrevPage={pageContext.hasPrevPage} hasNextPage={pageContext.hasNextPage} /> 
                </div>
                <div className="py-12">
                    {isFiltered && (
                        <div className="pb-8 border-b border-gray-200 dark:border-gray-700">
                            <Link
                                to="/Blog"
                                className="flex text-primary-500 hover:text-primary-600 dark:hover:text-primary-400">
                            <svg viewBox="0 -9 3 24" className="overflow-visible mr-3 w-auto h-6">
                                <path
                                d="M3 0L0 3L3 6"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                ></path>
                            </svg>
                                Back to view all posts
                            </Link>
                        </div>
                    )}
                    <FeaturdPostsSidebarComponent />
                    <div className="py-4 sticky top-16">
                        <span className="text-sm tracking-wide text-gray-500 dark:text-gray-400 mb-1 font-medium">
                            Support
                        </span>
                        <div className="flex flex-wrap">
                            <p className="text-base">If you've found anything on this blog useful, you can buy me a coffee. It's certainly not necessary but much appreciated!</p>
                            <p className="pt-6 text-center mx-auto">
                                <a href="https://www.buymeacoffee.com/surinderbhomra" target="_blank" rel="noreferrer" className="flex">
                                    <img src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png" alt="Buy Me A Coffee" width="210" height="60" className="my-0 inline" />
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BlogIndexListingComponent