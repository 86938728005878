import React from "react";
import { graphql } from "gatsby";
import LayoutComponent from "../components/global/layout";
import SEOComponent from "../components/global/seo";
import BlogIndexListingComponent from "../components/blog/post-index-listing";

const BlogCategoryLayout = ({ data, pageContext }) => {
  const { allMarkdownRemark } = data;
  
  return (
    <LayoutComponent>
      <SEOComponent title={`Categorised by '${pageContext.category.name}'${(pageContext.currentPage > 1 ? ` - Page ${pageContext.currentPage}` : "")} | Blog`} 
                    metaDescription={`${(pageContext.currentPage > 1 ? `Page ${pageContext.currentPage} of blog` : "Blog")} posts categorised by '${pageContext.category.name}'.`} 
                    shareImageIsLarge={true} />
      <div className="divide-y">
        <header className="pt-6 pb-8 space-y-2 md:space-y-5">
          <h1 className="text-3xl font-extrabold leading-9 tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl sm:leading-10 md:text-6xl md:leading-14">
            Blog
          </h1>
          <p>{`Categorised by '${pageContext.category.name}'.`}</p>
        </header>
        <BlogIndexListingComponent data={allMarkdownRemark} path={`/Blog/Category/${pageContext.category.slug}`} pageContext={pageContext} isFiltered={true} />
      </div>
    </LayoutComponent>
  )
}

export default BlogCategoryLayout

export const query = graphql`
  query blogPostsListByCategory($categorySlug: String, $skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {frontmatter: {draft: {eq: false}, categories: { elemMatch: {slug: {eq: $categorySlug}}}}, fields: {type: {eq: "post"}}}
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
          }
          html
          frontmatter {
            title
            summary
            date
            categories {
              name
              slug
            }
            tags
            teaserImage
          }
          timeToRead
        }
      }
    }
  }`